'use client';
import React from 'react';
import Image from 'next/image';
import { motion } from 'framer-motion';
import Logo from '@/assets/images/logo.webp';

const MotionImage = motion(Image);

const SplashScreen = () => {
  return (
    <div className="relative z-[100]">
      <motion.div
        initial={{
          width: '100vw',
          height: '100svh',
          top: 0,
          position: 'fixed',
          zIndex: -10, // Set the z-index here
          background: '#FDF9F3',
        }}
        animate={{
          width: ['100vw', '70vw', '40vw', '30vw', '16.53vw', '0vw'],
          height: ['100vw', '70vw', '40vw', '30vw', '16.53vw', '0vw'],
          top: 'auto',
          borderRadius: ['0%', '25%', '50%', '75%', '100%'],
        }}
        transition={{ duration: 0.4, delay: 0 }}
        className="left-1/2 -z-10 mx-auto flex h-full w-full -translate-x-1/2 items-center justify-center"
      />
      <MotionImage
        initial={{
          scale: 3,
          zIndex: 10,
        }}
        transition={{ duration: 0.4, delay: 0 }}
        animate={{ scale: 1 }}
        className="z-50 mx-auto h-[16.53vw] w-[16.53vw]"
        src={Logo}
        alt="Logo Image"
        sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 50vw"
        priority
        loading="eager"
      />
    </div>
  );
};

export default SplashScreen;
